import { Capacitor } from '@capacitor/core';

export function isWeb() {
	return Capacitor.getPlatform() === 'web';
}

export function isAndroid() {
	return window.CapacitorTestIsAndroid
		? window.CapacitorTestIsAndroid()
		: Capacitor.getPlatform() === 'android';
}

export function isIOS() {
	return window.CapacitorTestIsIOS
		? window.CapacitorTestIsIOS()
		: Capacitor.getPlatform() === 'ios';
}
